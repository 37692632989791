import React, { useState, useEffect, Fragment } from 'react'
import {MultiSelect} from "react-multi-select-component"
import Select from "react-select"
import {Button, InputGroup, InputGroupAddon, InputGroupText, Input, Label} from 'reactstrap'
import './UserDetails.css'
import backendAPI from '../../../../services/backend'
import AlertModal from '../../../Modals/alertModal/AlertModal'
import WaitingModal from '../../../Modals/waitingModal/WaitingModal'
import constants from '../../../../constants'


const UserDetails = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(true)
    const [isDisabledFlag, setisDisabledFlag] = useState(false)
    const [dropDownsDisable,setDropDownsDisable] = useState(false)
    const [alertHeader, setAlertHeader] = useState("Error")

    const [roleSelected, setRoleSelected] = useState({})
    const [userSelected, setUserSelected] = useState({})
    const [divisionsSelected, setDivisionsSelected] = useState([])
    const [queuesSelected, setQueuesSelected] = useState([])
    const [stateSelected, setStateSelected] = useState(false)
    const [regionSelected, setRegionSelected] = useState([])
    const [departmentSelected, setdepartmentSelected] = useState([])


    const [rolesList, setRolesList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    
    const [channelsList, setChannelsList] = useState([])
    const [divisionsList, setDivisionsList] = useState([])
    const [queuesList, setQueuesList] = useState([])
    const [userList, setUserList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [displayQueueList, setDisplayQueueList] = useState()


    useEffect(() => {

        // const removeAll = (arrayToModify) => {
        //     const deleteIndex = arrayToModify.findIndex(obj => obj.value === "*")
        //     if ((deleteIndex > -1) && (arrayToModify.length > 1))
        //         arrayToModify.splice(deleteIndex, 1)
        //     return arrayToModify
        // }

        const replaceAll = (arrayToReplace) => {
            if ((arrayToReplace.length === 1) && (arrayToReplace[0].value === "*")) {
                return true
            }
            return false

        }
        

        const getUserDetails = async () => {
            try {
            const getUserMeResponse = await backendAPI.getUsersMe(sessionStorage.getItem(constants.GC_TOKEN))
            console.log(JSON.stringify(getUserMeResponse));
            setRolesList(getUserMeResponse.user.roles ? getUserMeResponse.user.roles.filter((x => x.value !== '*')) : [])
            setDivisionsList(getUserMeResponse.user.divisions ? getUserMeResponse.user.divisions.filter((x => x.value !== '*')) : [])
            setQueuesList(getUserMeResponse.user.queues ? getUserMeResponse.user.queues.filter((x => x.value !== '*')) : [])
            setUserList(getUserMeResponse.user.users ? getUserMeResponse.user.users.filter((x => x.value !== '*')) : [])
            setRegionList(getUserMeResponse.user.regions ? getUserMeResponse.user.regions.filter((x => x.value !== '*')) : [])
            setDepartmentList(getUserMeResponse.user.department ? getUserMeResponse.user.department.filter((x => x.value !== '*')) : [])
            sessionStorage.setItem(constants.SESSION_KEY_GETUSER_ME_RESPONSE, JSON.stringify(getUserMeResponse))
            setRoleSelected(roleSelected.length>0 ? roleSelected : null)
            

            if (props.userId) {

                const getUserbyIDResponse = await backendAPI.getUserByID(sessionStorage.getItem(constants.GC_TOKEN), props.userId)
                /*
                console.log(`getUserbyIDResponse Object is  ${JSON.stringify(getUserbyIDResponse)}`);                
                console.log(`getUserbyIDResponse Keys are ${JSON.stringify(Object.keys(getUserbyIDResponse.user))}`);
                console.log(`getUserbyIDResponse Role is ${JSON.stringify(getUserbyIDResponse.user.role)}`);
                console.log(`getUserbyIDResponse regions is ${JSON.stringify(getUserbyIDResponse.user.region)}`);
                console.log(`getUserbyIDResponse Disabled is ${JSON.stringify(getUserbyIDResponse.user.disabled)}`);
                console.log(`getUserbyIDResponse department is ${JSON.stringify(getUserbyIDResponse.user.department)}`);
                console.log(`getUserbyIDResponse id is ${JSON.stringify(getUserbyIDResponse.user.id)}`);
                console.log(`getUserbyIDResponse authorizedDivisions are ${JSON.stringify(getUserbyIDResponse.user.authorizedDivisions)}`);
                console.log(`getUserbyIDResponse authorizedQueues are ${JSON.stringify(getUserbyIDResponse.user.authorizedQueues)}`);
                console.log(`getUserbyIDResponse authorizedQueues length are ${JSON.stringify(getUserbyIDResponse.user.authorizedQueues.length)}`);
                console.log(`getUserbyIDResponse authorizedDivisions length are ${JSON.stringify(getUserbyIDResponse.user.authorizedDivisions.length)}`);
*/
               
                let mydepartment = getUserbyIDResponse.user.department;
                setRoleSelected(getUserMeResponse.user.roles ? getUserMeResponse.user.roles.filter((x => x.value == getUserbyIDResponse.user.role.value)) : []);
                setUserSelected({'value':getUserbyIDResponse.user.id ,'label': getUserbyIDResponse.user.email});
                setisDisabledFlag(true);

                let allregions = getUserMeResponse.user.regions;
                console.log(`myregions are ${JSON.stringify(JSON.stringify(getUserbyIDResponse.user.region))}`);
                console.log(`myregions contains check 2 ${JSON.stringify(allregions.filter((x => getUserbyIDResponse.user.region.includes(x.label))))}`);

                
                let selectedRegions = allregions.filter((x => getUserbyIDResponse.user.region.includes(x.label)));
                console.log(`selectedRegions are ${JSON.stringify(selectedRegions)}`);
                let selectedDivision = getUserMeResponse.user.divisions.filter((x => getUserbyIDResponse.user.authorizedDivisions.includes(x.value)));
                let selectedQueue = getUserMeResponse.user.queues.filter((x => getUserbyIDResponse.user.authorizedQueues.includes(x.value)));
                if(getUserbyIDResponse.user.authorizedDivisions.length == 1 && getUserbyIDResponse.user.authorizedDivisions[0]== "*"){
                    selectedDivision = getUserMeResponse.user.divisions ? getUserMeResponse.user.divisions.filter((x => x.value !== '*')) : [];
                    //console.log('Overriding all Division List');
                }

                if(getUserbyIDResponse.user.authorizedQueues.length == 1 && getUserbyIDResponse.user.authorizedQueues[0]== "*"){
                    selectedQueue = getUserMeResponse.user.queues ? getUserMeResponse.user.queues.filter((x => x.value !== '*')) : [];
                    //console.log('Overriding all Queue List');
                }
                if(selectedRegions.length == 1){
                    console.log(`We have only one region`);
                    console.log(selectedRegions[0].value== '*');
                    if(selectedRegions[0].value== '*'){
                        selectedRegions = getUserMeResponse.user.regions ? getUserMeResponse.user.regions.filter((x => x.value !== '*')) : [];
                    }
                    console.log(`Overriding all region List as ${JSON.stringify(selectedRegions)}`);
                }
                

                console.log(`allregions are ${JSON.stringify(allregions)}`);                
                console.log(`selectedRegions are ${JSON.stringify(selectedRegions)}`);
                //console.log(`selectedRegions are ${JSON.stringify(selectedRegions)}`);
                //console.log(`selectedDivision are ${JSON.stringify(selectedDivision)}`);
                //console.log(`selectedqueue are ${JSON.stringify(selectedQueue)}`);
                
                setRegionSelected(selectedRegions? selectedRegions.filter((x => x.value !== '*')) : []);
                setdepartmentSelected(mydepartment?mydepartment.filter((x => x.value !== '*')) : []);
                setDivisionsSelected(selectedDivision?selectedDivision.filter((x => x.value !== '*')): []);
                setQueuesSelected(selectedQueue.filter((x => x.value !== '*')));

                if (getUserbyIDResponse.user.disabled){
                    setStateSelected(getUserbyIDResponse.user.disabled);
                }
                sessionStorage.setItem(constants.SESSION_KEY_GETUSER_BY_ID_RESPONSE, JSON.stringify(getUserbyIDResponse));
                if(getUserbyIDResponse.user.role.value === "admin"){
                    setDropDownsDisable(true);
                }else{
                    setDropDownsDisable(false);
                }
                
            }

        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }

        }

        getUserDetails()

    }, [])
    
    const filterQueue =  (DivisionsInp,queueToFilter)=> {
        let selectedDivison = DivisionsInp.map( e => e.value)
        let queuesFilter = []
        let filteredQueue = []
        selectedDivison.forEach (element => {
            queuesFilter = queueToFilter.filter(x => x.divisionId === element)
            filteredQueue = filteredQueue.concat(queuesFilter)
        })
        return (filteredQueue)

    }


    return (
  
            <div className = "form-elements">
            <WaitingModal
            isOpen={isWaitingModalOpen}
            header='Loading User Details'
            />
             <AlertModal
            isOpen={!!error}
            header={alertHeader}
            toggle={() => { setError(undefined) }}
            body={error}
            />
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>User Email <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <Select className = "single-select"
                       placeholder = "Select..."
                        options={userList}
                        value={userSelected}
                        onChange={e => { setUserSelected(e) }}
                        isDisabled={isDisabledFlag}
                    
                    />                
            </InputGroup>

            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Role <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                 <Select className = "single-select"
                       placeholder = "Select..."
                        options={rolesList}
                        value={roleSelected}
                        onChange={e => { setRoleSelected(e) 
                            if(e.value === "admin"){
                                setDropDownsDisable(true)
                                //setChannelsSelected(channelsList)
                                setDivisionsSelected(divisionsList)
                                setQueuesSelected(queuesList)
                            }
                            else{
                                setDropDownsDisable(false)
                                //setChannelsSelected([])
                                setDivisionsSelected([])
                                setQueuesSelected([])
                            }

                             }}
                    />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Region<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down region-multi-select"
                            options={regionList}
                            value={regionSelected}
                            onChange={e => { setRegionSelected(e) }}
                            labelledBy="Select"
                            required = 'true'
                        />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Department<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down department-multi-select"
                            options={departmentList}
                            value={departmentSelected}
                            onChange={e => { setdepartmentSelected(e) }}
                            labelledBy="Select"
                            required = 'true'
                        />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Divisions<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={divisionsList}
                    value={divisionsSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => {setDivisionsSelected(e)
                                    const selectedDivison = e;
                                    setDisplayQueueList(filterQueue(selectedDivison,queuesList))
                                    setQueuesSelected(filterQueue(selectedDivison,queuesSelected))
                                    }}
                    labelledBy="Select"
                />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Queues<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={displayQueueList ? displayQueueList : 
                            (filterQueue(divisionsSelected,queuesList).length>0 ? filterQueue(divisionsSelected,queuesList) : queuesList)}
                    value={queuesSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => setQueuesSelected(e)}
                    labelledBy="Select"
                />
            </InputGroup>
            <br />
            <Input addon type="checkbox" onChange={e => setStateSelected(e.target.checked)} checked={stateSelected} />
            <Label>&nbsp; Disable </Label>
            <br />
            <br />
                                    
            <Button color="primary" onClick={() => {
            
                if (!userSelected || !roleSelected || !regionSelected || !departmentSelected || !divisionsSelected || !queuesSelected
                    || (roleSelected && roleSelected.length === 0) 
                    || (divisionsSelected && divisionsSelected.length === 0) 
                    || (queuesSelected && queuesSelected.length === 0)
                    || (regionSelected && regionSelected.length === 0)
                    || (departmentSelected && departmentSelected.length === 0)
                    ) {
                    setError(`Please enter all the required fields before submitting`)
                    setAlertHeader("Validation Error")
                    return
                }

                props.submit({
                    user:userSelected,
                    region: regionList.length === regionSelected.length ? [{
                        "value": "*",
                        "label": "All"
                    }] : regionSelected,
                    department: departmentList.length === departmentSelected.length ? [{
                        "value": "*",
                        "label": "All"
                    }] : departmentSelected,
                    role: rolesList.length === roleSelected.length ? [''] : roleSelected,               
                    divisions: divisionsList.length === divisionsSelected.length ? [{
                        "value": "*",
                        "label": "All"
                    }] : divisionsSelected,
                    queues: queuesList.length === queuesSelected.length ? [{
                        "value": "*",
                        "label": "All"
                    }] : queuesSelected,
                    disabled: stateSelected

            })}}>{props.okButtonText ? props.okButtonText : 'OK'} </Button>
            &nbsp;&nbsp;
            <Button color="secondary" disabled={props.loading? true : false} onClick={() => props.cancel()}>{props.cancelButtonText ? props.cancelButtonText : 'Cancel'}</Button>
            <br />
            <br />
                    </div>
            
    )      
}

export default UserDetails