import React, { useState, useEffect } from 'react'
import {MultiSelect} from "react-multi-select-component"
//import DatetimeRangePicker from 'react-datetime-range-picker';
import './AccessReportSearchCriteria.css'
import moment from 'moment';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const AccessReportSearchCriteria = (props) => {
    const { clients, users, actions } = props
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [selectedClients, setSelectedClients] = useState([])
    const [selectedActions, setSelectedActions] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [startSearch, setStartSearch] = useState(false)
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date

    const search = async () => {
        try {
            setStartSearch(true)
            if (!dateRange[0] || !dateRange[1]) return
            console.log('searching...')
            const searchCriteria = new Map()
            if (dateRange[0]) searchCriteria.set('startDate', dateRange[0])
            if (dateRange[1]) searchCriteria.set('endDate', dateRange[1])
            if (selectedActions) {
                if (selectedActions.length === actions.length) // all
                    searchCriteria.set('actions', '*')
                else
                    searchCriteria.set('actions', selectedActions.map(x => x.value))
            }
            
            props.search(searchCriteria)
    } catch (error) {
        console.log(error);                
    }
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    const getDatePickerClassName = () => {
        return !startSearch || (startDate && endDate) ?
            'input-group d-inline-flex w-auto'
            : 'input-group d-inline-flex w-auto date-picker-error'
    }
    function onSelectDate(dateArray, dateString) {
        console.log(`onSelectDate value is ${JSON.stringify(dateString)}`);
        setdateRange(dateString);
    }

    return (
        <div class="search-wrap pl-2">
            <div class="row justify-content-start">
                <div class="col col-12 col-xl-3">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Users</span>
                        </div>
                        <MultiSelect
                            options={users}
                            value={selectedUsers}
                            onChange={e => { setSelectedUsers(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
            </div>
            <div class='row justify-content-start'>
                <div class="col col-12 col-xl-3">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Actions</span>
                        </div>
                        <MultiSelect
                            options={actions}
                            value={selectedActions}
                            onChange={e => { setSelectedActions(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
                <div class='col col-12 col-xl-auto'>
                    <div class="input-group d-inline-flex align-items-center w-auto">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Interval</span>
                        </div>
                        <RangePicker placement="bottomLeft" style={{borderRadius:"5px",padding:"7px"}} onChange={onSelectDate} />                        
                    </div>
                </div>
            </div>
            <br />
            <div className='row justify-content-center d-flex flex-row-reverse bd-highlight'>
                <div class='col col-2'>
                    <button type="button" class="btn btn-primary  btn-space" onClick={search}>Search</button>
                    <button type="button" class="btn btn-secondary btn-space" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>

    )
}

export default AccessReportSearchCriteria