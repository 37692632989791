import React, { useState, useEffect } from 'react'
import ReactTable from "react-table-6";
import { Collapse, Tooltip } from 'reactstrap'
import Header from '../../header/Header'
import Footer from '../../footer/Footer';
import AccessReportSearchCriteria from '../../accessReportSearchCriteria/AccessReportSearchCriteria'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faCopy, faDownload } from '@fortawesome/free-solid-svg-icons'
import backend from '../../../services/backend'
import AlertMessage from '../../alertMessage/AlertMessage'
import WaitingModal from '../../Modals/waitingModal/WaitingModal'
import AlertModal from '../../Modals/alertModal/AlertModal'
import moment from 'moment'
import utils from '../../../services/utils'
import constants from '../../../constants'
import './AccessReport.css'

const AccessReport = (props) => {
    const [clients, setClients] = useState(undefined)
    const [actions, setActions] = useState(undefined)
    const [users, setUsers] = useState(undefined)
    const [searchAlertVisible, setSearchAlertVisible] = useState(false)
    const [searchAlertMessage, setSearchAlertMessage] = useState()
    const [audit, setAudit] = useState([])
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [error, setError] = useState()
    const [waitingHeader, setWaitingHeader] = useState()
    const [role, setRole] = useState()

    // Collapsable
    const [isSearchCriteriaOpen, setIsSearchCriteriaOpen] = useState(true)

    // Tooltips
    const [searchAccessReportTooltipOpen, setSearchAccessReportTooltipOpen] = useState(false)
    const [downloadTooltipOpen, setDownloadTooltipOpen] = useState(false)

    useEffect(() => { // initial load
        const fetchData = async () => {
            setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
            try {
                setWaitingHeader('Loading users')
                setIsWaitingModalOpen(true)
                const promises = []
                promises.push(backend.getUserListAll(sessionStorage.getItem(constants.GC_TOKEN)))
                promises.push(backend.getUsersMe(sessionStorage.getItem(constants.GC_TOKEN)))
                const responses = await Promise.all(promises)
                for (const response of responses) {
                    if (response.users) { // users list
                        console.log('access.report.users found')
                        //console.log(`Users are ${JSON.stringify(response.users)}`)
                        const users = response.users.map(x => ({
                            label: `${x.email}`,
                            value: x.id
                        }))
                        setUsers(users)
                    }

                    if (response.user) { // clients & actions for current user
                        console.log('access.report.user found')
                        setClients(response.user.clients)
                        setActions(response.user.actions)
                    }
                }
            } catch (error) {
                setError(`An error occured while fetching users:${JSON.stringify(error.message)}`)
                console.log(error)
            } finally {
                setIsWaitingModalOpen(false)
            }
        }
        fetchData()
    }, [])

    const fetchAudit = async (searchCriteria) => {
        try {
            setWaitingHeader('Loading audit')
            setIsWaitingModalOpen(true)
            const response = await backend.getAuditAccessReport(sessionStorage.getItem(constants.GC_TOKEN), searchCriteria)
            // console.log('fetchData.response:', response)
            if (response.records_returned < response.total_records) {
                setSearchAlertVisible(true)
                setSearchAlertMessage(`Showing ${response.records_returned} of ${response.total_records} records. To find specific set of results, please refine your search criteria.`)
            }
            setAudit(response.audit)
            console.log(`Audit Search Response are ${JSON.stringify(response.audit)}`);
            sessionStorage.setItem(constants.SESSION_KEY_AUDIT, JSON.stringify(response.audit))

        } catch (error) {
            setError(`An error occured while fetching audit access report:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }
    }

    const search = async (searchCriteria) => {
        console.log('AccessReport.searchCriteria:', searchCriteria)
        fetchAudit(searchCriteria)
        toggleSearchCriteria(false)
        console.log(JSON.stringify(audit));
    }

    const toggleSearchCriteria = () => setIsSearchCriteriaOpen(!isSearchCriteriaOpen)

    const DownloadReport = async () => {
        utils.JSONToCSVConvertor(audit, `R2S_AccessReport_${moment().format()}`, true)
    }

    return (
        <div id="component-wrap">
            <Header role={role} />
            <div id="component-toolbar">
                <button id="searchAccessReport" onClick={toggleSearchCriteria}><FontAwesomeIcon className="fa-2x" icon={faFilter} /></button>
                <button id="downloadReport"
                    disabled={audit && audit.length > 0 ? false : true}
                    onClick={DownloadReport}><FontAwesomeIcon className="fa-2x" icon={faDownload} />
                </button>
            </div>
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingHeader}
            />
            <Tooltip placement="bottom" isOpen={searchAccessReportTooltipOpen}
                target="searchAccessReport"
                toggle={() => { setSearchAccessReportTooltipOpen(!searchAccessReportTooltipOpen) }}>
                Define access report criteria
            </Tooltip>
            <Tooltip placement="bottom" isOpen={downloadTooltipOpen}
                target="downloadReport"
                toggle={() => { setDownloadTooltipOpen(!downloadTooltipOpen) }}>
                Download report to CSV
            </Tooltip>
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <AlertMessage
                message={searchAlertMessage}
                visible={searchAlertVisible}
                setVisible={x => { setSearchAlertVisible(x) }}
            />
            <Collapse isOpen={isSearchCriteriaOpen}>
                {clients && users && actions &&
                    <AccessReportSearchCriteria
                        search={search}
                        cancel={() => { toggleSearchCriteria(false) }}
                        users={users.filter((x => x.value !== '*'))}
                        actions={actions.filter((x => x.value !== '*'))}
                    />}
            </Collapse>
            <ReactTable
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={15}
                minRows={0}
                filterable
                className="-highlight -striped table-container"
                data={audit}
                columns={[{
                    Header: 'Date',
                    accessor: 'timestamp',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {moment(row.value).format("MMM Do YYYY, h:mm:ss a Z")}
                            </div>
                        )
                    },
                    filterable: false
                },
                {
                    Header: 'User Email',
                    accessor: 'userEmail',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="div-center overflow">
                                <div className="overflow div-center">
                                {row.original.userEmail}
                            </div>
                                
                            </div>
                        )
                    },
                },
                {
                    Header: 'Action',
                    accessor: 'action',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Details',
                    accessor: 'details',
                    headerClassName: 'headerColumn',
                    style: { 'white-space': 'unset' } ,
                    Cell: row => {
                        return (
                            <div className="div-center">
                                <div className="div-center overflow">
                                {row.original.details}
                            </div>
                                
                            </div>
                        )
                    },
                    filterable: false
                },
                {
                    Header: 'Copy Details',
                    accessor: 'details',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="div-center overflow">
                                <button onClick={() => {
                                    navigator.clipboard.writeText(row.original.details)
                                    setSearchAlertVisible(true)
                                    setSearchAlertMessage(`Details copied to clipboard.`)
                                }}><FontAwesomeIcon icon={faCopy} /></button>
                            </div>
                        )
                    },
                    filterable: false
                }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            cursor: 'pointer'
                        }
                    }
                }}
                getTrProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                getTrGroupProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                defaultFilterMethod={(filter, row) => {
                    const id = filter.pivotId || filter.id;
                    return (row[id] !== undefined ?
                        String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                        : true
                    );
                }}
            />
            <Footer />
        </div>)
}



export default AccessReport